.inventory .render-disabled {
	background-color: #f0f0f0 !important;
	background-image: linear-gradient(#fff, #bbb);
}

.inventory .inventory-container.render-disabled .row::before {
	content: 'SCANNING';
	position: absolute;
	margin: 0 auto;
	width: 100%;
	top: 100px;
	left: 20px;
	z-index: 9999;
	width: 100%;
	font-size: 3em;
	font-weight: 700;
	color: #000;
	font-style: italic;
	min-height: 50px;
}

.inventory .carousel-control-next-icon {
	background-color: rgb(255,255,255,0.15);
	border-radius: 80px;
	padding: 5px;
	filter: invert(1) !important;
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23fff%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z%27/%3e%3c/svg%3e") !important;
}

.inventory .carousel-control-prev-icon {
	background-color: rgb(255,255,255,0.15);
	border-radius: 80px;
	padding: 5px;
	color: #000;
	filter: invert(1) !important;
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23fff%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z%27/%3e%3c/svg%3e") !important;   
}

.inventory .card .content {
	padding: 8px;
}

.inventory .card .header {
	font-size: 1.1em;
	font-weight: 500;
	line-height: 1.2;
	margin-top: 0;
	margin-bottom: 0.2rem;
}

.inventory .card .meta {
	font-size: 0.7em !important;
	margin-bottom: 0.6rem;
}

.inventory .card .description {
	font-size: 0.8em;
}

.inventory .card > .placeholder img {
	width: 100%;
	min-height: 245px;
}

.inventory .product-images, .datasheets, .pinout-images, .circuit-images {
	cursor: pointer;
}

.inventory .product-images .carousel-item img, .pinout-images .carousel-item img, .circuit-images .carousel-item img {
	min-height: 247px;
	max-height: 247px;
}

.inventory .datasheets .carousel-inner {
	min-height: 247px;
	max-height: 247px;
}

.inventory .ui.menu.shortcuts {
	line-height: 1;
	min-height: unset;
}

.inventory .ui.menu.shortcuts .item {
	font-size: 0.90vw;
	justify-content: center;
	width: 33%;
}

.inventory .ui.menu.shortcuts .item:nth-child(3):before {
	width: 0;
}

.inventory .ui.menu.shortcuts .item:nth-child(3) {
	content: none;
}

.inventory .placeholder {
	cursor: default;
}

.inventory .product.productshot {
	height: 75px;
	width: auto;
}

.inventory .part-metadata-info h1, .part-metadata-info h2 {
	font-size: 1em;
	padding: 0;
	margin: 0;
	line-height: 0.9em;
	font-weight: 700;
	display: inline-block;
}

.inventory .part-metadata-info h2 {
	color: #2185d0;
	font-size: 0.9em;
}

.inventory .part-metadata-info {
	font-size: 0.9em;
}

.inventory .part-metadata-info b {
	font-weight: 500;
}

.inventory .part-metadata-info .inactive {
	font-weight: 500;
	color: #c00;
}

.inventory .ui.toggle.checkbox.left label {
	padding-left: 0;
	padding-right: 4em;
}

.inventory .ui.checkbox.left input,
.inventory .ui.toggle.checkbox.left label:before {
	left: auto;
	right: 0;
}

.inventory .ui.toggle.checkbox.left label:after {
	left: auto;
	right: 2.2em;
	transition: background 0.3s ease 0s, right 0.3s ease 0s;
}

.inventory .ui.toggle.checkbox.left input:checked + label:after {
	left: auto;
	right: 0;
	transition: background 0.3s ease 0s, right 0.3s ease 0s;
}

.inventory .ui.toggle.checkbox.small {
	font-size: 0.9em;
}

.inventory .ui.toggle.checkbox.small label:before {
	transform: scale(0.75);
}

.inventory .ui.toggle.checkbox.small label:after {
	transform: scale(0.75);
}

.inventory section {
	margin-right: 40px;
}

.inventory .barcodescan {
	position: relative;
	top: 3px;
}

.inventory .ui.celled.grid.inventory-container {
	margin: 0.3em 0 1em 0;
}

.inventory .ui.dropdown .menu>.item  {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
}

.inventory .ui.dropdown .menu>.item>.icon,.ui.dropdown .menu>.item>.MuiSvgIcon-root {
	position: absolute;
	top: 14px;
	left: 2px;
}

.inventory .ui.dropdown .menu>.item>.MuiSvgIcon-root {
	color: #ccc;
}

.inventory .ui.dropdown .menu>.item>.text {
	display:flex;
	justify-content: start;
	margin: 0;
	padding-left: 10px;
}

.inventory .ui.dropdown .menu>.item>.description {
	display:flex;
	justify-content: start;
	flex: 1;
	font-size: 0.8em;
	margin: 0;
	padding-left: 10px;
	float: none;
	font-weight: normal;
}

.inventory .ui.dropdown.label.currency .menu {
	width: 170px;
	max-height: 300px;
	overflow-y: scroll;
	bottom: unset;
}

.inventory .ui.dropdown.label.currency .item {
	font-size: 0.9em;
}

.inventory .ui.dropdown.label.currency .item.selected {
	font-size: 0.9em;
	color: #2185d0;
}

.inventory .ui.dropdown.label.currency .item .description {
	font-size: 0.8em;
	font-weight: normal;
}

.inventory .part-metadata-buttons {
	display: flex;
	align-items: end;
	justify-content: end;
}

.inventory .part-metadata-buttons .ui.button {
	height: 43px;
	line-height: 1.21428571em;
	padding: 0.68em 1.5em 0.68em 1.5em;
}

.inventory .part-metadata-buttons .MuiSvgIcon-root {
	margin-right: 5px;
}

.inventory .part-metadata-buttons .MuiSvgIcon-root.technical-specs {
	color: rgb(8, 114, 212);
}

.inventory .part-metadata-buttons .MuiSvgIcon-root.compliance {
	color: rgb(3, 175, 3);
}

.inventory .part-metadata-buttons .MuiSvgIcon-root.cadModels {
	color: rgb(231, 66, 231);
}